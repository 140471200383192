<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="商家名称" slot="companyName" align="center">
        <template slot-scope="{ row }">
          <span
            @click="toStoreShop(row.companyName)"
            style="color: #0981ff; text-decoration: underline; cursor: pointer"
            >{{ row.companyName }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="地址" slot="address" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.address"
            class="item"
            effect="dark"
            :content="row.address"
            placement="top"
          >
            <div class="Remarks">
              {{ row.address }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="启用状态" slot="isEnable" align="center">
        <template slot-scope="{ row }">
          <el-switch @change="(e) => Enable(e, row.id)" v-model="row.isEnable">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="首次启用时间" slot="firstOpenTime" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.firstOpenTime ? row.firstOpenTime : "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="到期时间" slot="expireTime" align="center">
        <template slot-scope="{ row }">
          <span :class="{ redColor: row.expireTag }">{{
            row.expireTime ? row.expireTime : "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注说明" slot="remark" align="center">
        <template slot-scope="{ row }">
          <!-- <div>{{ row.remark ? row.remark : "暂无说明" }}</div> -->
          <div>
            <el-tooltip
              v-if="row.remark"
              class="item"
              effect="dark"
              :content="row.remark"
              placement="top"
            >
              <div class="Remarks">
                {{ row.remark }}
              </div>
            </el-tooltip>
            <span v-else>暂无说明</span>
          </div>
          <el-button @click="remarkClick(row)" size="mini" type="text" round>{{
            row.remark ? "修改" : "添加"
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <!-- slot-scope="{ row }" -->
          <el-button size="mini" type="text" round @click="eddBtn(row.id)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="text"
            round
            @click="toAdvantage(row.companyId)"
            >门店优势</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>

    <!-- s删除 -->

    <!-- 状态提示 -->
    <el-dialog
      :title="EnableTitle"
      :visible.sync="EnableDialogVisible"
      :close-on-click-modal="false"
      width="400px"
      @closed="EnableClosed"
    >
      <div class="mb-20">
        确认要{{ EnableData.status ? "开启" : "关闭" }}该商户线上回收业务吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="EnableCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="EnableConfirmDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 备注说明 -->
    <el-dialog
      :title="remarkTitle"
      :visible.sync="remarkDialogVisible"
      :close-on-click-modal="false"
      width="400px"
      @closed="remarkClosed"
    >
      <el-input
        type="textarea"
        maxlength="100"
        v-model="remarkData.remark"
        :autosize="{ minRows: 7, maxRows: 7 }"
        show-word-limit
        placeholder="请输入备注内容"
        resize="none"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="remarkDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="remarkConfirmDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  data() {
    return {
      loading: false,
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      EnableTitle: "",
      EnableDialogVisible: false,
      EnableData: {
        id: "",
        status: "",
      },
      remarkData: {
        id: "",
        remark: "",
      },
      delDialogVisible: false,
      remarkTitle: "",
      delFromData: {
        command: "",
        id: "",
      },
      tableData: {
        companyName: "",
        name: "",
      },
      remarkDialogVisible: false,
      tableColumns: [
        { slotName: "companyName" },
        { slotName: "address" },
        { label: "联系人", prop: "name" },
        { label: "添加时间", prop: "createTime" },
        { label: "C端展示方式", prop: "mallShow" },
        { slotName: "isEnable" },
        // { label: "首次启用时间", prop: "firstOpenTime" },
        { slotName: "firstOpenTime" },
        { slotName: "expireTime" },
        { slotName: "remark" },
        { slotName: "operation" },
      ],
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.tableData.companyName = newVal.companyName;
      this.tableData.name = newVal.name;
      this.handleCurrentChange();
    },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        companyName: this.tableData.companyName,
        name: this.tableData.name,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api.shoppingMallList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 跳转商家列表
    toStoreShop(companyName) {
      let routeData = this.$router.resolve({
        path: "/StoreShop/index",
        query: { companyName },
      });
      window.open(routeData.href, "_blank");
    },
    // 修改状态
    EnableClosed() {
      this.EnableDialogVisible = false;
      this.handleCurrentChange();
    },
    Enable(e, id) {
      this.EnableDialogVisible = true;
      this.EnableTitle = e ? "启用业务" : "关闭业务";
      this.EnableData.id = id;
      this.EnableData.status = e;
    },
    EnableCancel() {
      this.EnableDialogVisible = false;
      this.handleCurrentChange();
    },
    EnableConfirmDialog() {
      _api.updateEnable(this.EnableData).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.EnableDialogVisible = false;
        this.handleCurrentChange();
      });
    },
    // 备注说明
    remarkClosed() {
      this.remarkDialogVisible = false;
      // this.handleCurrentChange();
    },
    remarkClick(row) {
      this.remarkData.id = row.id;
      this.remarkData.remark = row.remark;
      this.remarkTitle = row.remark ? "修改备注" : "添加备注";
      this.remarkDialogVisible = true;
    },
    remarkConfirmDialog() {
      _api.updateRemark(this.remarkData).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.remarkDialogVisible = false;
        this.handleCurrentChange();
      });
    },
    // 编辑
    eddBtn(id) {
      this.$router.push("/onlineRecycling/merchants?id=" + id);
    },
    // 门店优势
    toAdvantage(companyId) {
      this.$router.push("/onlineRecycling/advantage?companyId=" + companyId);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mr-50 {
    margin-right: 50px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .font-w {
    font-weight: bold;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .flex-as {
    display: flex;
    align-items: flex-start;
  }
  .redColor {
    color: red;
  }
}
</style>
