<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="90"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <div slot="AddSlot">
        <!-- <el-button size="small" type="warning" @click="addModel('import')"
          >一键导入</el-button
        > -->
        <el-button size="small" type="primary" @click="toAddMerchant"
          >+ 添加商家</el-button
        >
      </div>
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      initData: null,
      problemDialogVisible: false,
      // dialogVisible: false,
      formItemList: [
        {
          key: "companyName",
          type: "input",
          labelName: "商家名称",
          placeholder: "输入商家名称",
        },
        {
          key: "name",
          type: "input",
          labelName: "联系人",
          placeholder: "输入联系人",
        },
      ],
    };
  },
  created() {
    this.handleConfirm();
  },
  watch: {},
  methods: {
    handleConfirm(data,cd) {
      let SeachParams = {};
      if (data) {
        SeachParams = {
          companyName: data.companyName,
          name: data.name,
          pageNum: 1,
          pageSize: 10,
          disuseLoding: () => {
            if (typeof cd === 'function') {
              cd()
            }
          },
        };
      }
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    toAddMerchant() {
      this.$router.push("/onlineRecycling/merchants");
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
